import power from './power.png'
function About() {
  return (
    <>
      <div style={{marginTop:'15rem',width:'100%',height:'10px'}} className="about-scroll"></div>

      <div className="container about">
        <div className="row">
          <div className="col-md-6 text-center">
            <img alt="about" src={power} className="img-fluid" />
          </div>
          <div className="col-md-6">
            <h2 className="main-title about-h2">ABOUT</h2>
            <p className="main-p">
            At MD Power Solutions, we are dedicated to providing top-quality solar power products and services. 
          Whether you're interested in residential or commercial solar installations, we have the expertise 
          and experience to meet your needs. From solar panels and inverters to battery storage solutions and 
          maintenance services, we offer a comprehensive range of products and services to help you harness 
          the power of the sun.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
export default About;
