import { Link } from "react-scroll";
import logo from "./logo.png";

function Navbar() {
  document.addEventListener("scroll", function (e) {
    if (window.screen.width < 768 && window.scrollY > 690) {
      const gotop = document.querySelector(".gotop");
      gotop.classList.add("display");

      const nav = document.querySelector(".navbar");

      nav.classList.add("navopened");
    } else if (window.screen.width > 768 && window.scrollY > 220) {
      const gotop = document.querySelector(".gotop");
      gotop.classList.add("display");

      const nav = document.querySelector(".navbar");

      nav.classList.add("navopened");
    } else {
      const nav = document.querySelector(".navbar");
      const gotop = document.querySelector(".gotop");
      gotop.classList.remove("display");
      nav.classList.remove("navopened");
    }
  });

  function openBar() {
    const bar = document.querySelector(".bar");
    bar.classList.toggle("opened");
  }

  return (
    <nav className="navbar">
      <div className="container">
        <div className="row align-items-center"> {/* Added align-items-center */}
          <div className="col-auto"> {/* Added col-auto */}
            <div className="d-flex align-items-center"> {/* Added d-flex align-items-center */}
              <img src={logo} alt="Logo" style={{ height: "auto", width: "70px" }} /> {/* Adjusted height and width */}
            </div>
          </div>
          <div className="col"> {/* Added col */}
            <ul className="bar">
              <li>
                <Link
                  onClick={openBar}
                  activeClass="active"
                  spy={true}
                  smooth={true}
                  duration={1000}
                  to="headerbg"
                >
                  Home
                </Link>
              </li>
              <li>
                <Link
                  onClick={openBar}
                  activeClass="active"
                  to="services"
                  spy={true}
                  smooth={true}
                  duration={1000}
                >
                  Services
                </Link>
              </li>
              <li>
                <Link
                  onClick={openBar}
                  to="about-scroll"
                  spy={true}
                  smooth={true}
                  duration={1000}
                  activeClass="active"
                >
                  About
                </Link>
              </li>
              <li>
                <Link
                  onClick={openBar}
                  to="contact"
                  spy={true}
                  smooth={true}
                  duration={1000}
                  activeClass="active"
                >
                  Contact
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-auto"> {/* Added col-auto */}
            <div className="button" onClick={openBar}>
              <div className="burger"></div>
              <div className="burger"></div>
              <div className="burger"></div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
