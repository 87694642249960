// src/components/ProductDetail.js
import React from 'react';
import { Link } from 'react-scroll';

function ProductDetail({ product, onClose }) {
  if (!product) return null;

  return (
    <div style={styles.overlay}>
      <div style={styles.modal}>
        <img
          src="https://cdn.pixabay.com/photo/2022/03/23/02/48/cross-7086307_1280.png"
          alt="Cancel"
          style={styles.cancelIcon}
          onClick={onClose}
        />
        <img src={product.image} alt={product.name} style={styles.image} />
        <div style={styles.content}>
          <h2 style={styles.title}>{product.name}</h2>
          <p style={styles.description}>{product.description}</p>
          <Link
            to="contact"
            spy={true}
            smooth={true}
            duration={1000}
            activeClass="active"
          >
            <button style={styles.contactButton}>Contact</button>
          </Link>
        </div>
      </div>
    </div>
  );
}

const styles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  },
  modal: {
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '8px',
    width: '90%',
    maxWidth: '500px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    position: 'relative',
  },
  cancelIcon: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    width: '25px',
    height: '25px',
    cursor: 'pointer',
  },
  image: {
    width: '100%',
    height: 'auto',
    maxHeight: '300px',
    objectFit: 'cover',
    borderRadius: '8px',
  },
  content: {
    marginTop: '20px',
  },
  title: {
    fontSize: '1.5rem',
    marginBottom: '10px',
    color: '#333',
  },
  description: {
    marginBottom: '20px',
    color: '#666',
  },
  contactButton: {
    padding: '10px 20px',
    backgroundColor: '#28a745',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
  },
  contactButtonHover: {
    backgroundColor: '#218838',
  },
};

export default ProductDetail;
