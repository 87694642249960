import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faMapMarkerAlt, faPhone, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { faInstagram, faFacebook } from '@fortawesome/free-brands-svg-icons';
import { Link as ScrollLink, animateScroll as scroll } from 'react-scroll';

const handleEmailClick = () => {
  window.location.href = 'mailto:mdpowersolutions5@gmail.com';
};

const handleGmailClick = () => {
  window.location.href = 'mailto:mdsolarsolutions18@gmail.com';
};

function Footer() {
  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="side1">
            <div className="row">
              <div className="col-md-3">
                <h1 className="logo">MD Power Solutions</h1>
                <p className="footer-text">
                  At MD Power Solutions, we specialize in top-quality solar power products and services.
                </p>
              </div>
              <div className="col-md-3">
                <p className="footer-title">Important Link</p>
                <ul>
                  <li>
                    <ScrollLink
                      spy={true}
                      smooth={true}
                      duration={1000}
                      to="headerbg"
                    >
                      Home
                    </ScrollLink>
                  </li>
                  <li>
                    <ScrollLink
                      to="services"
                      spy={true}
                      smooth={true}
                      duration={1000}
                    >
                      Services
                    </ScrollLink>
                  </li>
                  <li>
                    <ScrollLink
                      to="about-scroll"
                      spy={true}
                      smooth={true}
                      duration={1000}
                    >
                      About Us
                    </ScrollLink>
                  </li>
                  <li>
                    <ScrollLink
                      to="contact"
                      spy={true}
                      smooth={true}
                      duration={1000}
                    >
                      Contact
                    </ScrollLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="side2">
            <div className="row">
              <div className="col-md-3">
                <p className="footer-title">Contact</p>
                <ul>
                  <li>
                    <FontAwesomeIcon
                      icon={faEnvelope}
                      style={{ color: 'rgb(202 202 202)' }}
                    />
                    <span onClick={handleEmailClick} style={{ color: '#919191', cursor:'pointer' }}>
                      <b> mdpowersolutions5@gmail.com</b>
                    </span><br></br>
                    <b style={{ color: '#919191', cursor:'pointer' }}>- Andhra Pradesh</b>
                  </li>
                  <li>
                    <FontAwesomeIcon
                      icon={faEnvelope}
                      style={{ color: 'rgb(202 202 202)' }}
                    />
                    <span onClick={handleGmailClick} style={{ color: '#919191', cursor:'pointer' }}>
                      <b> mdsolarsolutions18@gmail.com</b>
                    </span><br></br>
                    <b style={{ color: '#919191', cursor:'pointer' }}>- Gujarat</b>
                  </li>
                  <li>
                    <FontAwesomeIcon
                      icon={faMapMarkerAlt}
                      style={{ color: 'rgb(202 202 202)' }}
                    />
                    <ScrollLink to="section1"> Shop No. 24/1/1886, Revenue Ward No. 24-1, Opp. Baarasahid
                      Dargah, Near Sikandar Cafe, Nellore, Andhra Pradesh,
                      Pincode - 524003
                    </ScrollLink>
                  </li>
                  <li>
                    <FontAwesomeIcon
                      icon={faPhone}
                      style={{ color: 'rgb(202 202 202)' }}
                    />
                    <ScrollLink to="section2"> +91 9154765904, Andhra Pradesh</ScrollLink>
                  </li>
                  <li>
                    <FontAwesomeIcon
                      icon={faPhone}
                      style={{ color: 'rgb(202 202 202)' }}
                    />
                      <ScrollLink to="section2"> +91 8469008686, Gujarat</ScrollLink>
                  </li>
                </ul>
              </div>
              <div className="col-md-3">
                <p className="footer-title">Social Media</p>
                <ul>
                  <li>
                    <FontAwesomeIcon
                      icon={faInstagram}
                      style={{ color: 'rgb(235 9 184)' }}
                    />{' '}
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.instagram.com/mdpowersolutions"
                    >
                      Instagram
                    </a>
                  </li>
                  <li>
                    <FontAwesomeIcon
                      icon={faFacebook}
                      style={{ color: '#1877F2' }}
                    />{' '}
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.facebook.com/profile.php?id=61558485149182"
                    >
                      Facebook
                    </a>
                  </li>
                  {/* <li>
                    <FontAwesomeIcon
                      icon={faLinkedin}
                      style={{ color: '#0077B5' }}
                    />{' '}
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.linkedin.com/"
                    >
                      Linkedin
                    </a>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button onClick={() => scroll.scrollToTop(2500)} className="gotop">
        <FontAwesomeIcon icon={faArrowUp} style={{ color: '#333' }} />
      </button>
    </footer>
  );
}

export default Footer;
