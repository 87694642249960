import Card from "./Card";
function Services() {
  return (
    <div className="container services">
        <h2 className="main-title text-center">SERVICES</h2>
        <div className="card-cover">
            <div className="col-md-12">
                <div className="row">
                    <div className="col-md-4 mb-2">
                        <Card title="Solar Installation" img="https://media.istockphoto.com/id/1182213816/vector/vector-isometric-solar-panel-installation.jpg?s=612x612&w=0&k=20&c=7oCXyW3xCpOqWmj9_QkxhwgUcUlC2CYfYNwnHM27w3I=" text="MDPowerSolutions offers expert solar installation services, delivering efficient and sustainable solutions for residential, commercial, and industrial needs." />
                    </div>
                    <div className="col-md-4 mb-2">
                    <Card title="Electicity Service" img="https://t3.ftcdn.net/jpg/05/67/79/78/360_F_567797859_CIAICt1fhN5OuxkD6ZY2eVk0dvuTTCE2.jpg" text="MDPowerSolutions delivers top-notch electricity services for residential, commercial, and industrial clients, ensuring reliability, safety, and sustainability." />
                    </div>
                    <div className="col-md-4 mb-2">
                    <Card title="EV Battrey Service" img="https://img.freepik.com/premium-vector/flat-isometric-concept-illustration-house-with-electric-car-charger_18660-2341.jpg" text="MDPowerSolutions offers expert EV battery services, ensuring optimal performance and longevity for electric vehicles. Trust us for reliable maintenance and efficient solutions to keep your EV running smoothly." />
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}
export default Services;