// src/components/ProductCard.js
import React from 'react';

function ProductCard({ product, onClick }) {
  return (
    <div style={styles.card} onClick={() => onClick(product)}>
      <img src={product.image} alt={product.name} style={styles.image} />
      <div style={styles.content}>
        <h3 style={styles.title}>{product.name}</h3>
        <p style={styles.description}>{product.description}</p>
        <button style={styles.button}>View Details</button>
      </div>
    </div>
  );
}

const styles = {
  card: {
    border: '1px solid #ddd',
    borderRadius: '8px',
    overflow: 'hidden',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    cursor: 'pointer',
    transition: 'transform 0.3s ease',
    maxWidth: '400px',
    gap: '1rem',
    margin: 'auto',
    marginTop: '1rem',
    marginBottom: '1rem',
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
  },
  image: {
    width: '100%',
    height: 'auto',
    objectFit: 'cover',
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
  },
  content: {
    padding: '20px',
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontSize: '1.2rem',
    marginBottom: '10px',
    color: '#333',
  },
  description: {
    marginBottom: '15px',
    color: '#666',
    flex: '1',
  },
  cost: {
    fontSize: '1rem',
    fontWeight: 'bold',
    color: '#007bff',
    marginBottom: '10px',
  },
  button: {
    backgroundColor: '#28a745',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    padding: '10px 15px',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
    alignSelf: 'flex-end',
  },
};

export default ProductCard;
