import React, { useState } from 'react';
import emailjs from 'emailjs-com';

function Contact() {
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    number: '',
    subject: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Use your EmailJS service ID, template ID, and user ID
    emailjs.sendForm('service_d0z5cki', 'template_esbwt3y', e.target, 'YPZFJYCbd3ThgiBns')
      .then((result) => {
        console.log('Email sent successfully:', result.text);
        setShowSnackbar(true); // Show the snackbar when email is sent successfully
        setFormData({
          name: '',
          email: '',
          number: '',
          subject: '',
          message: ''
        }); // Reset the form fields
        // Add any success message or redirection logic here
      }, (error) => {
        console.error('Email sending failed:', error.text);
        // Add any error handling logic here
      });
  };

  return (
    <div className="container contact">
      <h2 className="main-title text-center">CONTACT</h2>
      <form onSubmit={handleSubmit}>
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-3 mb-1">
              <input name="name" placeholder="Name" className="contact-input" value={formData.name} onChange={handleChange} required style={{color:"black"}} />
            </div>
            <div className="col-md-3 mb-1">
              <input name="email" placeholder="Email" className="contact-input" value={formData.email} onChange={handleChange} required style={{color:"black"}}/>
            </div>
            <div className="col-md-3 mb-1">
              <input name="number" placeholder="number" className="contact-input" value={formData.number} onChange={handleChange} required style={{color:"black"}}/>
            </div>
            <div className="col-md-3 mb-1">
              <input name="subject" placeholder="Subject" className="contact-input" value={formData.subject} onChange={handleChange} required style={{color:"black"}}/>
            </div>
          </div>
        </div>
        <br />
        <div className="col-md-12">
          <textarea name="message" placeholder="Message" className="contact-textarea" value={formData.message} onChange={handleChange} required style={{color:"black"}}/>
        </div>
        <br />
        <div className="row">
          <div className="col-md-12">
            <input className="form-btn" type="submit" value="Send Message"/>
          </div>
        </div>
      </form>
      {showSnackbar && (
        <div className="snackbar" style={{ color: 'black' }}>
          Email sent successfully!
        </div>
      )}
    </div>
  );
}

export default Contact;
