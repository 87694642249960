// src/pages/Products.js
import React, { useState } from 'react';
import ProductCard from '../components/ProductCard';
import ProductDetail from '../components/ProductDetail';

const productsData = [
  {
    id: 1,
    name: 'Ongrid Solar System',
    description: 'At MDPowerSolutions, we specialize in providing high-quality Ongrid Solar System services.',
    image: 'https://homescape.solar/off-grid-solar-systems/desktop/images/Power_Your_Whole_House.webp',
  },
  {
    id: 2,
    name: 'Off-grid Solar System',
    description: 'At MDPowerSolutions, we offer comprehensive Off-Grid Solar System services tailored to meet the unique energy requirements of our clients.',
    image: 'https://couleenergy.com/wp-content/uploads/2020/03/Off-Grid-Solar-Systems-Black-Mono-Solar-Panel-1024x577-1024x585.jpg',
  },
  {
    id: 3,
    name: 'Hybrid Solar System',
    description: 'At MDPowerSolutions, we specialize in Hybrid Solar System solutions that combine the benefits of both On-Grid and Off-Grid systems.',
    image: 'https://5.imimg.com/data5/SELLER/Default/2022/2/KN/BO/LV/20539148/solar-panel-installation-service-500x500.jpg',
  },
  {
    id: 4,
    name: 'Solar Water Pump ',
    description: 'At MDPowerSolutions, we provide cutting-edge Solar Water Pump solutions designed to harness the power of the sun to meet your water pumping needs efficiently and sustainably.',
    image: 'https://www.apollopowersystems.com/wp-content/uploads/2016/04/solar-water-pumping-solutions1-800x403.jpg',
  },
  {
    id: 4,
    name: 'Solar Street Light',
    description: 'At MDPowerSolutions, we offer state-of-the-art Solar Street Light solutions designed to illuminate roads, pathways, parks, and public spaces with clean and renewable energy.',
    image: 'https://fireflier.com/wp-content/uploads/2021/05/Solar-street-Light-80W-480x320.jpg',
  },
  {
    id: 5,
    name: 'Solar Panel',
    description: 'At MDPowerSolutions, we specialize in providing high-quality Solar Panel solutions tailored to meet the energy needs of residential, commercial, and industrial customers.',
    image: 'https://etap.com/images/default-source/product/photovoltaic-solar-panels/etap-photovoltaic-array-analysis-3.jpg?sfvrsn=b8a2bf7f_6',
  },
  {
    id: 6,
    name: 'Solar Inverter',
    description: '"At MDPowerSolutions, we offer advanced Solar Inverter solutions designed to efficiently convert solar energy into usable electricity for residential, commercial, and industrial applications.',
    image: 'https://images.jdmagicbox.com/quickquotes/images_main/luminous-nxg-2350-solar-inverter-2000-va-2222221801-st7dy4y4.png',
  },
  {
    id: 7,
    name: 'Solar Battery',
    description: 'At MDPowerSolutions, we provide top-of-the-line Solar Battery solutions to store excess solar energy generated by solar panels for later use.',
    image: 'https://sistinesolar.com/wp-content/uploads/2023/09/Best-solar-battery-storage-systems.jpg',
  },
  {
    id: 8,
    name: 'Solar on Rice mill',
    description: 'At MD Power Solutions paving the way for sustainable energy transformation in rice milling operations.',
    image: 'https://www.smergers.com/media/businessphoto/48206-1595700058-b5a7ad5d-7f0d-43c7-b75b-3c05cf895074.jpeg',
  },
  // Add more products as needed
];

function Products() {
  const [selectedProduct, setSelectedProduct] = useState(null);

  const handleCardClick = (product) => {
    setSelectedProduct(product);
  };

  const handleCloseDetail = () => {
    setSelectedProduct(null);
  };

  return (
    <div style={styles.container}>
      <h1>Our Solar Products</h1>
      <div style={styles.grid}>
        {productsData.map((product) => (
          <ProductCard key={product.id} product={product} onClick={handleCardClick} />
        ))}
      </div>
      <ProductDetail product={selectedProduct} onClose={handleCloseDetail} />
    </div>
  );
}

const styles = {
  container: {
    padding: '20px',
  },
  grid: {
    display: 'flex',
    flexWrap: 'wrap',
  },
};

export default Products;
